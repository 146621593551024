import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmationConfig } from '@fuse/services/confirmation/confirmation.types';
import { merge } from 'lodash-es';
import { BpConfirmationModalComponent } from '../components/bp-confirmation-modal/bp-confirmation-modal.component';
import { BpConfirmationConfig } from '../models/confirmation.types';
import { ComponentFactoryService } from './component-factory.service';

@Injectable({
  providedIn: 'root',
})
export class BpConfirmationService {
  private _defaultConfig: FuseConfirmationConfig = {
    title: 'Confirmación',
    message: 'Está seguro que desea realizar esta acción?',
    icon: {
      show: false,
      name: 'mat_outline:help_outline',
      color: 'primary',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Ok',
        color: 'primary',
      },
      cancel: {
        show: false,
        label: 'Cancelar',
      },
    },
    dismissible: false,
  };

  private _errorConfig: FuseConfirmationConfig = {
    title: 'Error!',
    message: 'Ocurrió un error al realizar la acción.',
    icon: {
      show: true,
      name: 'heroicons_outline:exclamation-circle',
      color: 'warn',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Ok',
        color: 'warn',
      },
      cancel: {
        show: false,
        label: 'Cancelar',
      },
    },
    dismissible: false,
  };
  /**
   * Constructor
   */
  constructor(
    private _matDialog: MatDialog,
    private componentFactoryService: ComponentFactoryService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  open(
    config: BpConfirmationConfig = {},
    type?: 'default' | 'error',
  ): MatDialogRef<BpConfirmationModalComponent> {
    // Merge the user config with the default config
    if (config.factoryBodyComponent) {
      config.bodyComponent = this.componentFactoryService.createComponent(
        config.factoryBodyComponent.component,
        config.factoryBodyComponent.inputs,
      );
    }
    let confirmationConfig: BpConfirmationConfig;
    if (type && type == 'error') {
      confirmationConfig = this._errorConfig;
    } else {
      confirmationConfig = this._defaultConfig;
    }
    const userConfig = merge({}, confirmationConfig, config);
    if (!config.title) {
      delete userConfig.title;
    }

    // Open the dialog
    return this._matDialog.open(BpConfirmationModalComponent, {
      autoFocus: false,
      width: userConfig.width,
      height: userConfig.height,
      maxWidth: '100%',
      disableClose: !userConfig.dismissible,
      data: userConfig,
      panelClass: 'fuse-confirmation-dialog-panel',
    });
  }
}
